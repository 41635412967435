export default [
  {
    text: "Выбор номинации",
    link: { name: "AchievementYearIndividualNomination" },
    key: "nominations",
    disabled: false,
  },
  {
    text: "Мои данные",
    link: { name: "AchievementYearIndividualData" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "AchievementYearIndividualPerformance" },
    key: "performance",
    disabled: false,
  },
  {
    text: "Портфолио",
    link: { name: "AchievementYearIndividualPortfolio" },
    key: "portfolio",
    disabled: false,
  },
];
